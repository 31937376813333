.home {
  background-color: #1d1d1d;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.top-wave {
  position: absolute;
  z-index: -1;
}
.waves {
  position: absolute;
  left: 0;
  bottom: -180px;
  width: 100%;
  z-index: -1;
}
.home .nav {
  max-width: 1215px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 27px;
}
.home .nav img {
  width: 84px;
  object-fit: contain;
}
.home .nav .btn {
  background-color: white;
}
.home .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 113px;
}
.home .main span {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #46a0f1;
}
.home .main h2 {
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  text-align: center;
  color: #ffffff;
  margin: 10px 0;
}
.home .main h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 25px;
}
.home .main p {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #8b8f97;
  max-width: 885px;
  margin-bottom: 40px;
}

.home .btn {
  width: 200px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  background: #46a0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .btn img {
  width: 10px;
  height: 12px;
  margin-left: 10px;
}
.home .main > img {
  width: 1085px;
  margin-top: -40px;
  z-index: -1;
}
@media (max-width: 1200px) {
  .home .main span {
    font-size: 14px;
    line-height: 16px;
  }
  .home .main h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .home .main h1 {
    font-size: 30px;
    line-height: 42px;
  }
  .home .main p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 34px;
  }
  .home .main > img {
    width: 780px;
    margin-top: -20px;
  }
  .waves {
    bottom: 0px;
  }
}

@media (max-width: 765px) {
  .home .main {
    padding: 0 24px;
    margin-top: 60px;
  }
  
  .home .main h1 {
    font-size: 24px;
    line-height: 122.7%;
  }
  .home .main span {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.06em;
  }
  .home .main h2 {
    font-size: 32px;
    line-height: 32px;
  }
  .home .main h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .home .main p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .home .main > img {
    width: 780px;
    margin-top: -20px;
  }
  .home .main > img {
    width: 120%;
  }
  .home .btn {
    width: 124px;
    height: 40px;
    font-size: 12px;
    line-height: 15px;
  }
}
@media (max-height: 621px) {
  .waves {
    bottom: 0 !important;
  }
  .home .main > img {
    margin-left: 120px;
    width: 190% !important;
    position: unset !important;
  }
}
@media (max-width: 489px) {
  .waves {
    bottom: 140px;
  }
  .home .main > img {
    position: absolute;
    bottom: -180px;
    width: 581px;
    left: -46px;
  }
}
@media (max-width: 340px) {
  .home .main {
    margin-top: 40px;
  }
  .home .main h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .home .main h1 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .home .main p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}
@media (max-width: 300px) {
  .home .nav > img {
    width: 100px;
  }
}
